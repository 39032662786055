import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import CTab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { withLocalize } from "react-localize-redux";
import isEqual from "react-fast-compare";
import Notifications from "react-notification-system-redux";
import ReactHtmlParser from "react-html-parser";
import instance from "../../axios";
import { checkPrivileges,selectAll } from "../../Helpers";
import Accumulators from "../Units/Components/accumulators";
import Sensors from "../Units/Components/sensors";
import { DeviceModal } from "../../Components/Devices/deviceModal";
import RawAttributes from "../Units/Components/rawAttributes";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import CloseIcon from '@material-ui/icons/Close';
 
const styles = (theme) => ({
  root: {
    fontFamily: "inherit",
  },
  scrollButtons: {
    color: "#333",
  },
  indicator: {
    backgroundColor: "#333",
  },
});

const VerticalTabs = withStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ ...other.style }}
      className="custom-unit-tabpanel-container"
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// --> parking form
function checkMinimalTripDistance(val, convertForApi) {
  return val;
}

function checkSpeedThreshold(val, convertForApi) {
  let initVal =
    (localStorage.getItem("userInfoFront") &&
      JSON.parse(localStorage.getItem("userInfoFront")).attributes.speedUnit) ||
    "";
  let value = val;
  let nVal = 0;
  if (convertForApi) {
    if (initVal && initVal !== "kn") {
      if (initVal === "kmh") {
        value = value * 0.539957;
      } else if (initVal === "mph") {
        value = value * 0.868976;
      }
    }
    nVal = value;
  } else {
    if (initVal && initVal !== "kn") {
      if (initVal === "kmh") {
        value = value * 1.852;
      } else if (initVal === "mph") {
        value = value * 1.15078;
      }
    }
    nVal = parseFloat(Math.round(value).toFixed(5));
  }
  return nVal;
}

const unitParkingAttFormat = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: checkMinimalTripDistance(100),
  speedThreshold: checkSpeedThreshold(1.61987),
  processInvalidPositions: false,
  useIgnition: 2,
  storeTime: 10,
};

const NotificationDataFormat = {
  always: "",
  attributes: {},
  calendarId: "",
  id: "",
  notificators: "",
  type: "",
};

const ComputedAttributesDataFormat = {
  description: "",
  attribute: "",
  expression: "",
  type: "",
};
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: "",
  id: "",
  description: "",
  type: "custom",
  textChannel: "",
};
const maintenanceDataFormat = {
  attributes: { status: true },
  name: "",
  type: "",
  start: "",
  period: "",
};

const locations = [
  "#allAttribute",
  "#basic",
  "#sensors",
  "#sharedDeviceAccumulators"
];

class MainView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: "allAttribute",
      currentId: 0,
      selecteditem: {},
      activeOperation: "",
      driverObj: "",
      address: "",
      lat: "",
      lng: "",
      deviceModal: false,
      openDriverModal: false,
      unitParkingAttributesform: { ...unitParkingAttFormat },
      notificationForm: { ...NotificationDataFormat },
      computedAttForm: { ...ComputedAttributesDataFormat },
      savedCommandsForm: { ...savedCommandsDataFormat },
      sendCommandsForm: { ...savedCommandsDataFormat },
      maintenanceForm: { ...maintenanceDataFormat },
      isVisableASOneBtn: false,
      visableAttribute: false,
      visableNotification: false,
      visableSavedCommands: false,
      visableMaintenance: false,
      visableGeofence: false,

      visableListNotification: true,
      visableListGeofence: true,
      visableListAttribute: true,
      visableListSavedCommands: true,
      visableListMaintenance: true,

      notificationCallStatus: "",
      attributeCallStatus: "",
      commandsCallStatus: "",
      maintenanceCallStatus: "",

      suggestions: [],
      isAdvSettingEnable: false,

      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMapData: null,
      rowsPerPage: 5,
    }
  }

 
  UNSAFE_componentWillMount() {
    this.setState(
      {
        currentId:
          this.props.devices3 && this.props.devices3.length
            ? parseInt(this.props.id)
            : 0,
      })

    if (!this.props.disableHash && this.props.hash) {
      let hash = locations.indexOf(this.props.hash);
      if (hash !== -1) {
        this.setState({
          currentTab: this.props.hash.substring(1, this.props.hash.length),
        });
      }
    }
    this.setState({selecteditem:this.props.selectedUnit})
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.id) {
      let suggestions = false;
      if (n.positions.length) {
        n.positions.map((p) => {
          if (parseInt(n.id) === parseInt(p.deviceId)) {
            let inputs = Object.keys(p.attributes);
            if (p.protocol === "bce") {
              for (var i = 1; i <= 16; i++) {
                inputs.push("input" + i);
              }
            }
            suggestions = true;
            this.setState({ suggestions: inputs });
          }
          return null;
        });
      }
      if (!n.disableHash && n.hash !== this.props.hash) {
        let hash = locations.indexOf(n.hash);
        if (hash !== -1) {
          this.setState({ currentTab: n.hash.substring(1, n.hash.length) });
        }
      }

      if (!suggestions) {
        this.setState({ suggestions: [] });
      }
      if (
        n.id &&
        parseInt(
          this.state.currentId ||
            (this.state.selecteditem ? this.state.selecteditem.id : 0)
        ) !== parseInt(n.id) &&
        n.devices3.length
      ) {
        this.setState(
          {
            currentId: parseInt(n.id),
          })
      }
    }
  }

    
  tabChange = (event, currentTab) => {
    this.setState({
      driverObj: "",
      openDriverModal: false,
      activeOperation: "",
    });
    this.setState({ currentTab });

  };

  onCloseModal = () => {
    this.setState(
      {
        activeOperation: "",
        driverObj: "",
        openDriverModal: false,
        isSendCommandModal: false,
      },
      () => {
        this.updateSelectedDrivers();
      }
    );
  };
 
 
   

  onCancel = () => {
    this.setState({
      onDeleteConfirmationGeofence: false,
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationUser: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false,
    });
  };
 
  // check for required field
  checkRequiredFields(computedAtt) {
    // changed attribute work
    const {
      defaultAttributes,
      selecteItem,
      maintenanceForm,
      computedAttForm,
      savedCommandsForm,
      notificationForm,
    } = this.state;
    if (computedAtt === "computedAtt") {
      let changed_comAt = !isEqual(selecteItem, computedAttForm);
      let { attribute, expression } = this.state.computedAttForm;
      if (
        attribute &&
        expression &&
        attribute.trim().length > 0 &&
        expression.trim().length > 0 &&
        changed_comAt
      ) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "Commands") {
      let changed_comm = !isEqual(selecteItem, savedCommandsForm);
      let { type, description } = this.state.savedCommandsForm;
      if (type && description && changed_comm) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "notification") {
      // attChangedValue is for attributes changing value
      let attChangedValue = !isEqual(
        defaultAttributes,
        notificationForm.attributes
      );
      if (attChangedValue) {
        this.setState({
          attributeChangesMessage: "Change in attributes is pending",
        });
      } else {
        this.setState({
          attributeChangesMessage: "",
        });
      }
      let changed_noti = !isEqual(selecteItem, notificationForm);
      let { type, notificators } = this.state.notificationForm;
      if (type && notificators && changed_noti) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "maintenance") {
      let Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes);
      if (Attvalue) {
        this.setState({
          attributeChangesMessage: "Change in attributes is pending",
        });
      } else {
        this.setState({
          attributeChangesMessage: "",
        });
      }
      let changed_main = !isEqual(selecteItem, maintenanceForm);
      let { type, start, period, attributes } = this.state.maintenanceForm;
      if (type && period && (start || attributes.startDate) && changed_main) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    }
  }

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };
 
  render() {
    const { currentTab, selecteditem, unitParkingAttributesform } = this.state;
    const { id, details } = this.props;
    const orientation = "horizontal";
    return (
      <div>
       
        <div className={"main-unit-modal-bg clearfix"}>
          {this.state.onDeleteConfirmationUnits && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDevice}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.slectedUnits.name}
            />
          )}
          {this.state.onDeleteConfirmationNotification && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeNotification}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "notification." + this.state.notificationForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationDriver && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDriver}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.driverForm.name}
            />
          )}
          {this.state.onDeleteConfirmationGeofence && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeGeofence}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.geofenceForm.name}
            />
          )}
          {this.state.onDeleteConfirmationAttributes && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeComputedAttributes}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                this.state.computedAttForm.description
              )}
            />
          )}
          {this.state.onDeleteConfirmationCommand && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeCommands}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "commands." + this.state.savedCommandsForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationMaintenance && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeMaintenance}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(this.state.maintenanceForm.type)}
            />
          )}
          {this.state.onDeleteConfirmationUser && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeUser}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.userForm.name}
            />
          )}
          {this.state.onDeleteConfirmationUnits && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDevice}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.slectedUnits.name}
            />
          )}
          {this.state.onDeleteConfirmationNotification && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeNotification}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "notification." + this.state.notificationForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationAttributes && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeComputedAttributes}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                this.state.computedAttForm.description
              )}
            />
          )}
          {this.state.onDeleteConfirmationCommand && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeCommands}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "commands." + this.state.savedCommandsForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationMaintenance && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeMaintenance}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(this.state.maintenanceForm.type)}
            />
          )}            
          <div style={{marginTop:5}}>
              <span style={{color:"#3A3A39", fontSize:18, fontWeight:600}}>{this.props.selectedUnit?.name || "-"}  </span>
              <span onClick={()=>this.props.closeMainModel()}> <CloseIcon
                    className={'feature-close-button'}
                    style={{ fill: '#3A3A39' }} 
                  />
              </span>
            </div>
          <div style={{marginTop:5}}>
            <VerticalTabs
                orientation={orientation}
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={this.tabChange}
                aria-label="Tabs"
                className="custom-tabs"
            >
                <CTab
                value="allAttribute"
                classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                }}
                label={
                    <span className="menu_item_text">
                    <span className="menu_item_text_inner">
                        {this.props.translate("allAttributes")}
                    </span>
                    </span>
                }
                {...a11yProps(0)}
                />
                <CTab
                value="basic"
                classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                }}
                label={
                    <span className="menu_item_text">
                    <span className="menu_item_text_inner">
                        {this.props.translate("basic")}
                    </span>
                    </span>
                }
                {...a11yProps(1)}
                />
                
                {this.props.selectedUnit &&
                this.props.selectedUnit.model &&
               // this.props.selectedUnit.model === "FX Tracker" ? null   :
                checkPrivileges(
                    "sensor"
                ) ? (
                <CTab
                    value="sensors"
                    classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                    }}
                    label={
                    <span className="menu_item_text">
                        <span className="menu_item_text_inner">
                          {this.props.translate("sensors")}
                        </span>
                    </span>
                    }
                    {...a11yProps(3)}
                />
                ) : null}
                <CTab
                value="sharedDeviceAccumulators"
                classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                }}
                label={
                    <span className="menu_item_text">
                    <span className="menu_item_text_inner">
                        {this.props.translate("reportEngineHours")}
                    </span>
                    </span>
                }
                {...a11yProps(4)}
                />
            </VerticalTabs>
          </div>
          
          {currentTab === "allAttribute" && (
            <TabPanel value={currentTab} index="allAttribute">
              {details && details.id && (
                <div>
                  <RawAttributes
                    positions={this.props.positions}
                    devices={this.props.devices3}
                    selectedDeviceId={this.props.details?.deviceId || ''}
                    translate={this.props.translate}
                  />
                </div>
              )}
            </TabPanel>
          )}
          {currentTab === "basic" && (
            <TabPanel value={currentTab} index="basic">
              {details && details.id && (
                <DeviceModal
                  {...this.props}
                  category={this.props.category}
                  onCloseModal={this.onCloseModal}
                  activeOperation={"edit"}
                  selecteditem={this.props.selectedUnit}
                  updateSelectedItem={this.updateSelectedItem}
                  addNewGroups={this.addNewGroups}
                />
              )}
            </TabPanel>
          )} 
          {this.props.selectedUnit && this.props.selectedUnit.model && 
              checkPrivileges(
              "sensor"
            ) ? (
            <>
              {currentTab === "sensors" && (
                <TabPanel value={currentTab} index="sensors">
                  <Sensors
                      openDeviceSettings={this.props.openDeviceSettings}
                      item1={this.props.item1}
                      sensorData={this.props.sensorData}
                      themecolors={this.props.themecolors}
                      deviceId={this.props?.details?.deviceId ?this.props?.details?.deviceId: this.props?.details?.id  }
                      deviceModal={this.state.deviceModal}
                      translate={this.props.translate}
                      suggestions={this.state.suggestions}
                      computedAttributesDeviceWise={this.props.computedAttributesDeviceWise}
                      isVisablBtn={checkPrivileges("sensorCreate")}
                      getSensors={this.props.getSensors}

                  />
                </TabPanel>
              )}{" "}
            </>
              ) : null}
            {currentTab === "sharedDeviceAccumulators" && (
              <TabPanel value={currentTab} index="sharedDeviceAccumulators">
                <Accumulators
                  deviceId={selecteditem.id}
                  translate={this.props.translate}
                  suggestions={this.state.suggestions}
                  computedAttributesDeviceWise={
                    this.props.computedAttributesDeviceWise
                  }
                  selectedUnit={this.props.selectedUnit}
                  selecteditem={selecteditem}
                  computedAttributesData={this.props.selectedComputedAttributes}
                  isVisablBtn={checkPrivileges("deviceUpdate")}
                />
              </TabPanel>
            )}
        </div>
       
      </div>
    );
  }
}

MainView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapState = (state) => ({
  devices: state.devices.data,
  devices3: state.devices3,
  groups: state.groups,
  drivers: state.drivers,
  geofences: state.geoFence,
  users: state.users,
  themecolors: state.themeColors,
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  selectednotification: state.selectednotification,
  selecteddriver: state.selecteddriver,
  selecteduser: state.selecteduser,
  selectedgeofence: state.selectedgeofence,
  geofenceDeviceWise: state.geofenceDeviceWise,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  sensorsDeviceWise: state.sensorsDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  calendars: state.calendars,
  category: state.category,
  positions: state.positions,
  roles: state.roles,
  ServerSetting: state.ServerSetting,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(
  withSnackbar(withLocalize(withStyles(styles)(MainView)))
);
