import React, { Component } from "react";
import Dialog from "../common/Dialog";
import {
  Map,
  Tooltip,
  TileLayer,
  FeatureGroup,
  Circle,
  Polygon,
  Polyline,
  ZoomControl,
  LayersControl,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import MarkerClusterGroup from "./../../Components/Maps/MarkerClusterGroup";
import CustomMarker from "./../../Components/Maps/CustomMarker";
import { MapTooltip } from "./../../Components/Maps/MapTooltip";
import "leaflet-control-geocoder";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { ReactComponent as LoaderIcon } from "./../../assets/tracks-loader.svg";
import TextField from "../common/TextField";
import Button from "../common/Button";
import { SketchPicker } from "react-color";
import { addGeoFence, updateGeoFence } from "../../Actions/Devices";
import Notifications from "react-notification-system-redux";
import { MapTileLayers } from "../../Reducers/Maps";
import moment from "moment";
import instance from "../../axios";
import { errorHandler } from "../../Helpers";

class GeofenceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minZoom: 3,
      lat: 25.097919,
      lng: 55.236683,
      zoom: 3,
      isVisible: false,
      bounds: null,
      layers: null,
      layerForm: {},
      colorPicker: {},
      edit: props.geofence ? true : false,
      onFullScreen: false,
      loading: false,
      currentLayer: null,
    };
    this.reactECref = {};
    this.mapReference = this.mapReference.bind(this);
  }
  onClose = () => {
    this.props.onClose();
  };
  mapReference(el) {
    if (el) {
      this.map = el.leafletElement;
      this.map &&
        L.Control.geocoder({
          position: "topright",
          placeholder: "Search location...",
          defaultMarkGeocode: false,
        })
          .on("markgeocode", (e) => {
            this.map &&
              this.map.fitBounds([
                [e.geocode.center.lat, e.geocode.center.lng],
              ]);

            if (this.props.getPostion && this.map) {
              this.props.getPostion(e.geocode.center, this.map.getZoom());
            }
          })
          .addTo(this.map);
    }
  }

  onFeatureGroupAdd = (e) => {
    this.map && this.map.fitBounds(e.target.getBounds());
    let layerForm = {};
    let layers = [];
    let layer = e.target;
    const { attributes, calendarId, id, name, description, area } =
      this.props.geofence;
    layerForm[layer._leaflet_id] = {
      attributes,
      calendarId,
      id,
      name,
      description,
      area,
    };
    layers.push(e.target);
    this.setState({ layers, layerForm }, () => {
      layer.bindPopup(this.htmlContent(layer._leaflet_id)).openPopup();
      this.reactECref.leafletElement._toolbars.edit._toolbarContainer.children[0].click();
    });
  };
  htmlContent = (id) => {
    if (this.state.layerForm[id]) {
      let html = '<div className="position-box"><strong>Name: </strong>';

      html += this.state.layerForm[id].name;
      html +=
        '<div className="position-box-body"><strong>Description</strong>:';
      html += this.state.layerForm[id].description || "";
      if (this.state.layerForm[id] && this.state.layerForm[id].attributes) {
        if (this.state.layerForm[id].attributes.type === "circle") {
          html +=
            "<br /><div><strong>Radius</strong>: " +
            ((this.state.layerForm[id].attributes.radius / 1000).toFixed(2) +
              " km") +
            "</div>";
        } else if (this.state.layerForm[id].attributes.type === "polyline") {
          html +=
            "<br /><div><strong>Polyline Distance</strong>: " +
            ((
              this.state.layerForm[id].attributes.polylineDistance || 0
            ).toFixed(2) +
              " km") +
            "</div>";
        } else {
          html +=
            "<br /><strong>Area</strong>: " +
            ((this.state.layerForm[id].attributes.area || 0).toFixed(2) +
              " m2");
        }
      }
      html += "</div></div>";
      return html;
    } else {
      return null;
    }
  };

  onDrawStop = (t) => {
    if (t && t.target && t.target._targets) {
      let layers = [];
      Object.keys(t.target._targets).map((layer, index) => {
        if (layer + "" !== t.target._containerId + "") {
          let l = t.target._targets[layer];
          if (l && l._path) {
            layers.push(this.prepareLayerForm(l));
          }
        }
        return null;
      });
      if (layers.length === 0) {
      }
      this.assignValues(layers);
    }
  };

  prepareLayerForm = (layer) => {
    let color = layer.options.color;
    let layerForm = this.state.layerForm;

    if (layerForm[layer._leaflet_id]) {
      color = layer.options.color =
        layerForm[layer._leaflet_id].attributes.color;
    }

    if (layer instanceof L.Polygon) {
      const seeArea = L.GeometryUtil.geodesicArea(layer._latlngs[0]);
      layer._path.attributes.fill.value = color;
      layer._path.attributes.stroke.value = color;

      let prepare = layer._latlngs[0].map((pos) => {
        return "" + pos.lat + " " + pos.lng + "";
      });

      if (!layerForm[layer._leaflet_id]) {
        layerForm[layer._leaflet_id] = {
          name: "New Polygon",
          calendarId: 0,
          description: "",
          area: "POLYGON((" + prepare.join(", ") + "))",
          attributes: {
            type: "polygon",
            color,
            latlng: layer._latlngs[0],
            area: seeArea,
          },
        };
      } else {
        layerForm[layer._leaflet_id] = {
          ...layerForm[layer._leaflet_id],
          area: "POLYGON((" + prepare.join(", ") + "))",
          attributes: {
            ...layerForm[layer._leaflet_id].attributes,
            color,
            latlng: layer._latlngs[0],
            area: seeArea,
          },
        };
      }
    } else if (layer instanceof L.Polyline) {
      layer._path.attributes.stroke.value = color;

      let distance = 0;
      let prepare = layer._latlngs.map((pos, index) => {
        distance += pos.distanceTo(layer._latlngs[index - 1] || pos);
        return "" + pos.lat + " " + pos.lng + "";
      });

      if (!layerForm[layer._leaflet_id]) {
        layerForm[layer._leaflet_id] = {
          name: "New Polyline",
          calendarId: 0,
          description: "",
          area: "LINESTRING (" + prepare.join(", ") + ")",
          attributes: {
            type: "polyline",
            color,
            latlng: layer._latlngs,
            polylineDistance: distance / 1000,
          },
        };
      } else {
        layerForm[layer._leaflet_id] = {
          ...layerForm[layer._leaflet_id],
          area: "LINESTRING (" + prepare.join(", ") + ")",
          attributes: {
            ...layerForm[layer._leaflet_id].attributes,
            color,
            latlng: layer._latlngs,
            polylineDistance: distance / 1000,
          },
        };
      }
    } else {
      layer._path.attributes.fill.value = color;
      layer._path.attributes.stroke.value = color;
      if (!layerForm[layer._leaflet_id]) {
        layerForm[layer._leaflet_id] = {
          name: "New Circle",
          calendarId: 0,
          description: "",
          area:
            "CIRCLE (" +
            layer._latlng.lat +
            " " +
            layer._latlng.lng +
            ", " +
            layer._mRadius +
            ")",
          attributes: {
            type: "circle",
            radius: layer._mRadius,
            color,
            latlng: [layer._latlng.lat, layer._latlng.lng],
          },
        };
      } else {
        layerForm[layer._leaflet_id] = {
          ...layerForm[layer._leaflet_id],
          area:
            "CIRCLE (" +
            layer._latlng.lat +
            " " +
            layer._latlng.lng +
            ", " +
            layer._mRadius +
            ")",
          attributes: {
            ...layerForm[layer._leaflet_id].attributes,
            radius: layer._mRadius,
            color,
            latlng: [layer._latlng.lat, layer._latlng.lng],
          },
        };
      }
    }

    this.setState({ layerForm }, () => {
      layer.bindPopup(this.htmlContent(layer._leaflet_id)).openPopup();
    });

    return layer;
  };

  assignValues = (layers) => {
    this.setState({ layers }, () => {
      if (this.state.edit) {
        this.save();
      }
    });
  };

  onChange = (field, index, event) => {
    let layerForm = this.state.layerForm;
    layerForm[index] = { ...layerForm[index], [field]: event.target.value };
    this.setState({ layerForm }, () => {
      let layer = this.state.layers.find(
        (l) => l._leaflet_id + "" === index + ""
      );
      layer._popup.setContent(this.htmlContent(layer._leaflet_id));
      this.checkRequiredFields();
    });
  };
  checkRequiredFields = () => {
    this.setState({
      isVisible: false,
    });
    Object.values(this.state.layerForm).map(({ name }) => {
      if (!name) {
        this.setState({
          isVisible: true,
        });
      }
    });
  };
  showColorPicker = (e, id) => {
    this.setState({
      colorPicker: {
        ...this.state.colorPicker,
        [id]: true,
      },
    });
  };

  hideColorPicker = (e, id) => {
    this.setState({
      colorPicker: {
        ...this.state.colorPicker,
        [id]: false,
      },
    });
  };

  updateColor = (e, id) => {
    let layer = this.state.layers.find((l) => l._leaflet_id + "" === id + "");
    layer.options.color =
      layer._path.attributes.fill.value =
      layer._path.attributes.stroke.value =
        e.hex;
    this.setState({
      layerForm: {
        ...this.state.layerForm,
        [id]: {
          ...this.state.layerForm[id],
          attributes: {
            ...this.state.layerForm[id].attributes,
            color: e.hex,
          },
        },
      },
    });
  };

  _onEditControlReady = (reactECref) => {
    this.reactECref = reactECref;
  };

  update = (updatedItem, id) => {
    if (!updatedItem.name) {
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("somthingWentWrongMessage"),
          autoDismiss: 10,
        })
      );
    } else {
      this.setState({ loading: true }, () => {
        instance({
          url: `/api/geofences/${id}`,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: {
            ...updatedItem,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((geofence) => {
            this.setState({ loading: false });
            this.props.dispatch(
              updateGeoFence({ ...geofence, visible: false })
            );
            this.props.dispatch(updateGeoFence({ ...geofence, visible: true }));
            this.onClose();
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("geofenceUpdated"),
                autoDismiss: 10,
              })
            );
          })
          //   }
          //   else{
          //     throw response
          //   }
          // })
          .catch((error) => {
            errorHandler(error, this.props.dispatch);
          });
      });
    }
  };

  addShape = (type) => {
    if (type === "circle") {
      this.reactECref.leafletElement._toolbars.draw._toolbarContainer.children[2].click();
    } else if (type === "polygon") {
      this.reactECref.leafletElement._toolbars.draw._toolbarContainer.children[1].click();
    } else if (type === "polyline") {
      this.reactECref.leafletElement._toolbars.draw._toolbarContainer.children[0].click();
    }
  };

  save = () => {
    if (this.state.edit) {
      Object.keys(this.state.layerForm).map((id) => {
        if (this.state.layers && this.state.layers.length) {
          let layer = this.state.layers[0];
          if (layer.editing._enabled) {
            this.reactECref.leafletElement._toolbars.edit._actionButtons[0].button.click();
          } else {
            let updatedItem = this.state.layerForm[id];
            delete updatedItem.visible;
            updatedItem.attributes.imported = false;
            this.update(updatedItem, updatedItem.id);
          }
        }
        return null;
      });
    } else if (
      this.state.layerForm &&
      Object.keys(this.state.layerForm).length
    ) {
      this.setState({ loading: true }, () => {
        Object.keys(this.state.layerForm).map((id, index) => {
          if (this.state.layerForm[id] && !this.state.layerForm[id].name) {
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate("somthingWentWrongMessage"),
                autoDismiss: 10,
              })
            );
            this.setState({ loading: false });
          } else {
            let updatedItem = this.state.layerForm[id];

            instance({
              url: `/api/geofences`,
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              data: {
                ...updatedItem,
              },
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then((geofence) => {
                this.setState({ loading: false });
                this.props.dispatch(
                  addGeoFence([{ ...geofence, visible: true }])
                );
                if (index === Object.keys(this.state.layerForm).length - 1) {
                  // if last child created
                  this.onClose();
                }
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate("geofenceCreated"),
                    autoDismiss: 10,
                  })
                );
              })
              //   }
              //   else{
              //     throw response
              //   }
              // })
              .catch((error) => {
                errorHandler(error, this.props.dispatch);
              });
          }
          return null;
        });
      });
    }
  };

  onFullScreen = () => {
    this.map && this.map.invalidateSize();
  };
  UNSAFE_componentWillMount() {
    if (this.props.center) {
      this.setState({
        ...this.props.center,
        zoom: this.props.zoom || this.props.logInUser.zoom,
      });
    } else if (this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    } else if (this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude,
      });
    }
    this.showUnits(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.showUnits(nextProps);
  }
  showUnits = (n) => {
    if (n.deviceRelatedData && Object.keys(n.deviceRelatedData).length) {
      this.markers = [];
      n.devices.data &&
        n.devices.data.map((deviceData, index) => {
          const position = n.deviceRelatedData[deviceData.id];
          const driver =
            n.drivers &&
            n.drivers.find(
              (dr) =>
                position &&
                position.exists &&
                position.driverId &&
                position.driverId === dr.id
            );
          const trailer =
            n.trailers &&
            n.trailers.find(
              (t) =>
                position &&
                position.exists &&
                position.attributes.trailerUniqueId &&
                position.attributes.trailerUniqueId === t.uniqueId
            );
          if (
            position &&
            position.exists &&
            deviceData &&
            deviceData.visible === true
          ) {
            this.markers.push(
              <CustomMarker
                key={deviceData.id}
                position={{
                  lat: position.latitude,
                  lng: position.longitude,
                  updated: moment(position.serverTime),
                }}
                rotationAngle={0}
                rotationOrigin="center"
                animationTime={
                  deviceData.id === this.props.trackId &&
                  this.state.animCount === 1
                    ? position.animationTime
                    : 0.5
                }
                tracking={this.props.trackId}
                icon={L.divIcon({
                  iconUrl:
                    "/assets/category/default/" +
                    (deviceData.category || "default") +
                    "top.svg",
                  iconSize: [50, 50],
                  iconAnchor: [25, 25],
                  tooltipAnchor: [0, -20],
                  className: "custom-marker",
                  html: `<img
                  style="transform: rotate(${position.course}deg)"
                    src=
                      '/assets/category/default/${
                        deviceData.category || "default"
                      }top.svg'

                    alt=''
                  />`,
                })}
                iconSize={[50, 50]}
              >
                <Tooltip direction={"top"}>
                  <MapTooltip
                    trailer={trailer}
                    themecolors={this.props.themecolors}
                    position={position}
                    driver={driver}
                    device={deviceData}
                    logInUser={this.props.logInUser}
                    translate={this.props.translate}
                  />
                </Tooltip>
              </CustomMarker>
            );
          }
          return null;
        });
    } else {
      this.markers = null;
    }
  };

  changeBaseLayer = (event) => {
    if (this.map) {
      const center = this.map.getCenter();
      this.map.options.crs = ["yandexMap", "yandexSat"].includes(
        event.layer.options && event.layer.options.id
      )
        ? L.CRS.EPSG3395
        : L.CRS.EPSG3857;
      this.map.setView(center);
    }

    this.setState({ currentLayer: event.layer.options });
  };

  render() {
    const position = [this.state.lat, this.state.lng];
    const drawOptions = {
      shapeOptions: {
        color: "red",
        opacity: 1,
        showArea: true,
      },
    };
    const settings = {
      rectangle: false,
      circle: false,
      circlemarker: false,
      marker: false,
      polyline: false,
      polygon: false,
    };
    const drawSettings = {
      ...settings,
      circle: drawOptions,
      polygon: drawOptions,
      polyline: drawOptions,
    };

    const body = [
      <>
        <FeatureGroup>
          <EditControl
            ref={this._onEditControlReady}
            draw={this.state.edit ? settings : drawSettings}
            edit={{
              edit: true,
              remove: this.state.edit ? false : true,
            }}
            //position='bottomleft'
            onDrawStop={this.onDrawStop}
            onEditStop={this.onDrawStop}
            onDeleted={this.onDrawStop}
          />
          {this.state.edit &&
            this.props.geofence &&
            this.props.geofence.attributes &&
            this.props.geofence.attributes.type === "circle" && (
              <Circle
                onAdd={(e) => this.onFeatureGroupAdd(e)}
                id={this.props.geofence.id}
                radius={this.props.geofence.attributes.radius}
                center={this.props.geofence.attributes.latlng}
                color={this.props.geofence.attributes.color}
              />
            )}
          {this.state.edit &&
            this.props.geofence &&
            this.props.geofence.attributes &&
            this.props.geofence.attributes.type === "polygon" && (
              <Polygon
                onAdd={(e) => this.onFeatureGroupAdd(e)}
                id={this.props.geofence.id}
                positions={this.props.geofence.attributes.latlng}
                color={this.props.geofence.attributes.color}
              />
            )}
          {this.state.edit &&
            this.props.geofence &&
            this.props.geofence.attributes &&
            this.props.geofence.attributes.type === "polyline" && (
              <Polyline
                onAdd={(e) => this.onFeatureGroupAdd(e)}
                id={this.props.geofence.id}
                positions={this.props.geofence.attributes.latlng}
                color={this.props.geofence.attributes.color}
              />
            )}
        </FeatureGroup>
        ,
        <ZoomControl position={"topright"} />
        {this.props.logInUser.attributes &&
        this.props.logInUser.attributes.clusters ? (
          <MarkerClusterGroup key={1}>{this.markers}</MarkerClusterGroup>
        ) : (
          this.markers
        )}
      </>,
    ];

    return (
      <Dialog
        disableFooter
        title={this.props.translate("sharedGeofence")}
        onClose={this.onClose}
        maxWidth="md"
        onFullScreen={this.onFullScreen}
      >
        <div className={ "geofence-modal " + (this.state.edit ? "hide-delete-btn " : " ")}>
          <div className="geofence-modal-form">
            {this.state.loading ? (
              <div
                className="geofence-modal-form-loading"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  borderRadius: 6,
                  background: this.props.themecolors.themeLightColor + "aa",
                  color: this.props.themecolors.themeInverse,
                  textAlign: "center",
                  zIndex: 99,
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flex: 1,
                }}
              >
                <LoaderIcon style={{ width: 30, height: 30, margin: "auto" }} />
              </div>
            ) : null}
            <div className="geofence-modal-form-inner">
              {!this.state.edit && (
                <p style={{ padding: 10, margin: 0 }}>
                  <Button
                    className="button-narrow"
                    onClick={(e) => this.addShape("circle")}
                  >
                    {this.props.translate("mapShapeCircle")}
                  </Button>
                  <Button
                    className="button-narrow"
                    onClick={(e) => this.addShape("polygon")}
                    style={{ marginLeft:6}}
                  >
                    {this.props.translate("mapShapePolygon")}
                  </Button>
                  <Button
                    className="button-narrow"
                    onClick={(e) => this.addShape("polyline")}
                    style={{ marginLeft:6}}
                  >
                    {this.props.translate("mapShapePolyline")}
                  </Button>
                </p>
              )}
              {this.state.layers && this.state.layers.length
                ? this.state.layers.map((layer) => (
                    <div style={{ position: "relative" }}>
                      {this.state.colorPicker[layer._leaflet_id] && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 9,
                          }}
                          onMouseLeave={(e) =>
                            this.hideColorPicker(e, layer._leaflet_id)
                          }
                        >
                          <SketchPicker
                            color={
                              this.state.layerForm[layer._leaflet_id] &&
                              this.state.layerForm[layer._leaflet_id].attributes
                                .color
                                ? this.state.layerForm[layer._leaflet_id]
                                    .attributes.color
                                : ""
                            }
                            onChangeComplete={(e) =>
                              this.updateColor(e, layer._leaflet_id)
                            }
                          />
                        </div>
                      )}
                      <TextField
                        fullWidth
                        label="Name"
                        required
                        value={
                          this.state.layerForm[layer._leaflet_id] &&
                          this.state.layerForm[layer._leaflet_id].name
                        }
                        onChange={(e) =>
                          this.onChange("name", layer._leaflet_id, e)
                        }
                      />
                      <TextField
                        fullWidth
                        // rows={5}
                        // multiline
                        value={
                          this.state.layerForm[layer._leaflet_id] &&
                          this.state.layerForm[layer._leaflet_id].description
                        }
                        label="Description"
                        onChange={(e) => this.onChange("description", layer._leaflet_id, e)}
                      />
                      <div style={{ margin: "15px 0" }}>
                        <label>{this.props.translate("selectColor")}</label>

                        <div
                          className="colorPicker-trigger"
                          style={{
                            background:
                              this.state.layerForm[layer._leaflet_id] &&
                              this.state.layerForm[layer._leaflet_id].attributes
                                .color
                                ? this.state.layerForm[layer._leaflet_id]
                                    .attributes.color
                                : "",
                            float: "right",
                            width: 48,
                            height: 14,
                            marginLeft: 10,
                            borderRadius: 14,
                            verticalAlign: "middle",
                            position: "relative",
                          }}
                          onClick={(e) =>
                            this.showColorPicker(e, layer._leaflet_id)
                          }
                        ></div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            {/* {!this.state.edit && (
               <div className="geofence-modal-form-footer">
               <Button
                 onClick={this.onClose}
                 style={{ marginRight: 10 }}
                 className={"button-white"}
               >
                 {this.props.translate("sharedCancel")}
               </Button>
               </div>
            )} */}
            
              <div className="geofence-modal-form-footer">
                <Button
                  onClick={this.onClose}
                  style={{ marginRight: 10 }}
                  className={"button-white"}
                >
                  {this.props.translate("sharedCancel")}
                </Button>
                {this.state.layers && this.state.layers.length && (
                  <Button disabled={this.state.isVisible} onClick={this.save}>
                    {this.state.edit
                      ? this.props.translate("update")
                      : this.props.translate("sharedCreate")}
                  </Button>
                )}
              </div>
            
          </div>
          <div className="geofence-modal-map">
            {[
              "yandexMap",
              "yandexSat",
              "osm",
              "carto",
              "googleTerrain",
              "googleSatellite",
              "googleHybrid",
              "googleRoad",
              "baidu",
              "",
            ].includes(this.props.mapLayer.id) ? (
              <Map
                key={1}
                ref={this.mapReference}
                onZoom={this.checkZoom}
                bounds={
                  this.state.bounds && this.state.bounds.length
                    ? this.state.bounds
                    : null
                }
                boundsOptions={this.setBoundOptions}
                style={{ height: this.props.height, width: this.props.width }}
                center={position}
                zoom={this.state.zoom}
                onBaselayerchange={this.changeBaseLayer}
                maxZoom={this.props.mapLayer.maxZoom}
              >
                {body}
                <LayersControl position="bottomright">
                  {MapTileLayers.map((layer) => (
                    <LayersControl.BaseLayer
                      id={layer.id}
                      checked={layer.id === this.props.mapLayer.id}
                      key={layer.id}
                      name={layer.name}
                    >
                      <TileLayer {...layer} minZoom={this.state.minZoom} />
                    </LayersControl.BaseLayer>
                  ))}
                </LayersControl>
              </Map>
            ) : null}
          </div>
        </div>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => ({
  mapLayer: state.mapLayer,
  deviceRelatedData: state.deviceRelatedData,
  devices: state.devices,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors,
});
export default connect(mapStateToProps)(withLocalize(GeofenceModal));
