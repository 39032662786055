import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from './Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { connect } from 'react-redux'
import Scrollbar from 'react-scrollbars-custom'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors
  }
}

const style = theme => ({})
const draggableId = new Date().getTime()

class PaperComponent extends React.Component {
  render () {
    return (
      <Draggable bounds='parent' handle={'.dragHandle' + draggableId}>
        <Paper {...this.props} />
      </Draggable>
    )
  }
}

function PaperDefault (props) {
  return <Paper {...props} />
}

class CustomDialog extends React.Component {
  state = {
    open: true,
    fullScreen: false
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.onClose()
  }

  handleScreen = () => {
    let v = this.state.fullScreen
    this.setState({ fullScreen: !v }, () => {
      if (this.props.onFullScreen) {
        this.props.onFullScreen(this.state.fullScreen)
      }
    })
  }

  render () {
    const { classes } = this.props

    let BackdropProps = {}

    if (this.props.invisible === true) {
      BackdropProps = { invisible: true }
    } else {
      BackdropProps = {
        //style: { backgroundColor: 'transparent' },
        classes: { root: classes.root }
      }
    }
    return (
      <Dialog
        fullScreen={this.props.fullScreen || this.state.fullScreen}
        classes={{
          paperFullScreen: 'dialog-full-screen',
          scrollPaper: 'scroll-auto',
          paperScrollPaper:
            'scroll-' + (this.props.overflow ? this.props.overflow : 'auto')
        }}
        open={this.state.open}
        onClose={this.handleClose}
        maxWidth={this.props.maxWidth || 'sm'}
        disableBackdropClick={true}
        PaperComponent={this.props.draggable ? PaperComponent : PaperDefault}
        BackdropProps={BackdropProps}
        scroll='body'
        fullWidth
      >
        <IconButton
          aria-label='onClose'
          onClick={this.handleClose}
          color='inherit'
          style={{
            position: 'absolute',
            top: 0,
            right: 45,
            zIndex: 999,
            borderRadius: 0,
            backgroundColor: this.props.themecolors.menuActiveBackground,
            color: this.props.themecolors.themeLightInverse,
            '&$hover': {
              backgroundColor: this.props.themecolors.themeLightColor
            },
            hover: {}
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        {!this.props.noFullScreen && (
          <IconButton
            onClick={this.handleScreen}
            color='inherit'
            style={{
              position: 'absolute',
              top: 0,
              right: 90,
              zIndex: 999,
              borderRadius: 0,
              backgroundColor: this.props.themecolors.menuActiveBackground,
              color: this.props.themecolors.themeLightInverse,
              '&$hover': {
                backgroundColor: this.props.themecolors.themeLightColor
              },
              hover: {}
            }}
          >
            {!this.state.fullScreen && <FullscreenIcon fontSize='small' />}
            {this.state.fullScreen && <FullscreenExitIcon fontSize='small' />}
          </IconButton>
        )}
        {/* {this.props.title && (
          <DialogTitle
            disableTypography={true}
            themecolors={this.props.themecolors}
            draggable={this.props.draggable}
            style={{
              backgroundColor: this.props.themecolors.menuActiveBackground,
              fontSize: 18,
              fontWeight: 500,
              minHeight: this.props.minHeight || 44,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: this.props.draggable ? 44 : 'def',
              color: this.props.themecolors.menuTextColor,
             }}
          >
            {this.props.title}
          </DialogTitle>
        )} */}
        <DialogContent
          style={{
            padding: this.props.bodyPadding ? this.props.bodyPadding : 0,
            minHeight: this.props.dialogHeight || 'inherit',
            backgroundColor: this.props.themecolors.backgroundColor,
            color: this.props.themecolors.textColor,
            overflow: this.props.overflow ? this.props.overflow : 'auto',
            borderTopLeftRadius:4,
            borderTopRightRadius:4,
            borderTop:`5px solid ${this.props.themecolors.menuActiveBackground}`
          }}
        >
          {/* {this.props.draggable && (
            <span
              className={'dragHandle' + draggableId}
              style={{
                position: 'absolute',
                //width: 44,
                height: this.props.minHeight || 44,
                fontSize: 32,
                cursor: 'move',
                zIndex: 9,
                left: 0,
                top: 0,
                textAlign: 'left',
                color: this.props.themecolors.themeLightInverse,
                right: 0,
                bottom: 0
              }}
            >
              <DragIndicatorIcon style={{ width: 44 }} />
            </span>
          )} */}
          {this.props.children}
        </DialogContent>
        {!this.props.disableFooter && (
          <DialogActions
            style={{
              margin: 0,
              padding: 10,
              // backgroundColor: this.props.themecolors.menuActiveBackground
            }}
          >
            {this.props.leftActions}
            {this.props.attributeChangesMessage && (
              <span style={{ display: 'block', padding: '10px 0' }}>
                {' '}
                {this.props.attributeChangesMessage}
              </span>
            )}
            <div style={{ marginLeft: 'auto' }} />
            <Button
              onClick={this.handleClose}
              variant='outlined'
              size='small'
             >
              {this.props.cancelText || this.props.CancelText || 'Cancel'}
            </Button>
            {this.props.isVisableBtn && this.props.headerActions}
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(style)(CustomDialog))
